/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  TeamButton,
  // HeaderWrapperV2,
  DividerLine,
  BuddyTabContainer,
  BuddyTab1,
  BottomCard,
  BottomCardWrapper
} from "../PeopleHomeV2/styles";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  fetchCompanyTeamUsers,
  fetchCompanyTeamCount,
  fetchUsersToAddToTeam,
  fetchTeamDetailsById,
  fetchDeleteTeam,
  addChallengeTeams,
  addChallengeTeamsV2,
} from "../../redux/actions";
import {
  AddMemberButton,
  CardWrapper,
  CustomDropdownMenue,
  CustomDropdownMenuItem,
  MemberData,
  TeamCard,
  AddNewTeam,
  TeamImage,
  Layout,
  Main,
  SeeAllStyledModal,
  TeamCardNew,
  MemberDataV2,
  CustomTitle,
  CaptainLogo,
  MemberDataV3,
  TeamCreationNotification,
  ToolTip,
  BottomStroke,
} from "./styles";
import { UserButton } from "../EngagementSection/styles";
import { PaginationCircleNew } from "../Journeys/styles";
import { PaginationCard } from "../DailyTipAdmin/styles";
import isNull from "lodash/isUndefined";
import Waiting from "../Waiting";
import AddUsersToTeamPopup from "./addEmployeeToTeamPopup";
import TeamDetailsPopup from "./teamDetailsPopup";
import { ImageUrl } from "../../utils/constants";
import { Modal } from "react-bootstrap";
import { OverlayTrigger, Popover } from "react-bootstrap";
import LazyImage from "../common/LazyImage/LazyImage";
import DeletePopup from '../common/deletePopup'
import HoverButton from "../common/HoverButton";
import {  AddEmployeeIcon, MenuDotsIcon,EmployeeCountIcon, AddTeamIcon, ViewProfileIcon,wellnessChampionIcon, menuDotsIcon, PaginationIcon, PrintNewIcon } from "../../utils/icons";
import { toast } from "react-toastify";
import { BASE_URL } from "../../utils/config";
import { GetTeamUsers } from "../../redux/constants/apiConstants";
import CSVList from "../AttendeesModal/CSVList";
import { t } from "i18next";

class AdminTeamMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTeams: true,
      onHover: -1,
      companyId: props.companyInfo["id"],
      pageNo: 1,
      limit: 15,
      paginationCount: "",
      minLimit: 1,
      maxLimit: "",
      countingValues: "",
      showModal: false,
      teamDetailsModal: false,
      teamId: null,
      SeeAll: false,
      SeeAllMembersData: [],
      teamIndex: null,
      teamName: null,
      maxTeamMemeberCount: null,
      teamMembersCount: null,
      timeout: false,
      teamButtonActive: false,
      showDeleteTeamPopup: false,
      deleteTeamID:null,
      teamUsers:[],
      csvHeaders:[ { label: "Team Name", key: "team_name" }, { label: "First Name", key: "first_name" },
        { label: "Last Name", key: "last_name" },
        { label: "Email", key: "email" },
        { label: "Department", key: "department" },
        { label: "Location", key: "city" },],
      isPrint:false
    };
  }
  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    this.fetchCompanyTeamUsersAPI();
    const { fetchCompanyTeamCount, teamsCount } = this.props;
    const { companyId } = this.state;
    if (isNull(teamsCount) || !teamsCount) {
      fetchCompanyTeamCount(companyId);
    }
    if (teamsCount && !isNull(teamsCount)) {
      this.setState({
        paginationCount: teamsCount["team_count"],
      });
    }

    this.fetchTeamUsers(companyId)
  }

  componentDidUpdate() {
    this.state.timeout &&
      window.setTimeout(() => {
        this.setState({ timeout: false });
      }, 5000);
  }

  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("mousedown", this.handleClick)  }

  fetchTeamUsers = async (companyId) => {
    const AuthToken = localStorage.getItem("AUTH_TOKEN");
    await fetch(BASE_URL + GetTeamUsers  + '/' + companyId, {
      method: "GET",
      headers: { AUTHTOKEN: AuthToken },
    })
      .then((res) => {
        res
          .json()
          .then((data) => {
            if (this._isMounted) {
              this.setState({teamUsers:data.data})
            }
          })
          .catch((error) => {
            toast.error(error);
          });
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  progress = () => {
    this.setState({ timeout: false });
  };

  UNSAFE_componentWillReceiveProps() {
    const { paginationCount } = this.state;
    if (
      !paginationCount &&
      paginationCount.length === 0 &&
      this.props.teamsCount &&
      !isNull(this.props.teamsCount)
    ) {
      this.setState({
        paginationCount: this.props.teamsCount["team_count"],
      });
    }
    if (paginationCount && this.state.countingValues !== paginationCount) {
      this.setState(
        {
          total: Math.ceil(paginationCount / 15),
          countingValues: paginationCount,
        },
        () => {
          if (this.state.total < 6) {
            this.setState({
              maxLimit: this.state.total,
            });
          } else {
            this.setState({
              maxLimit: 6,
            });
          }
        }
      );
    }
  }


  onHoverMouse = (id) => {
    this.setState({
      onHover: id
    })
  };

  onLeaveHoverMouse = () => {
    this.setState({
      onHover: -1
    })
  };

  showDeletePopup = (id) => {
    this.setState({ showDeleteTeamPopup: true, deleteTeamID:id });
  };

  hideDeletePopup = () => {
    this.setState({ showDeleteTeamPopup: false });
  };

  fallBack = (e) => {
    e.target.src = "/images/default.png";
  };

  fetchCompanyTeamUsersAPI = () => {
    const { fetchCompanyTeamUsers } = this.props;
    const { companyId, pageNo, limit } = this.state;
    fetchCompanyTeamUsers(companyId, pageNo, limit);
  };

  teamRedirect = () => {
    const { history } = this.props;
    history.push("/company/people/team-creation");
  };

  hideShowTeam = (status) => {
    const { history } = this.props;
    this.setState({ showTeams: status });
    if (!status) {
      history.push("/company/people");
    }
  };
  hideShowInactiveUsers = (status) => {
    const { history } = this.props;
    this.setState({ showTeams: status })
    if(status){
      history.push('/company/people/inactive-users')
    }
  }
  hideAddEmployeePopUp = () => {
    this.setState({
      showModal: false,
    });
  };
  hideTeamDetailsPopUp = () => {
    this.setState({
      teamDetailsModal: false,
    });
  };

  getPaginationCircle = () => {
    let circleButton = [];
    for (let i = this.state.minLimit; i <= this.state.maxLimit; i++) {
      circleButton.push(
        <PaginationCircleNew
          key={i}
          active={i == this.state.pageNo}
          onClick={() => this.setPagination(i)}
        >
          <div>{i}</div>
        </PaginationCircleNew>
      );
    }
    return circleButton;
  };

  handleSeeAll = (data) => {
    this.setState({ SeeAll: true, SeeAllMembersData: data });
  };

  hideSeeAllModal = () => {
    this.setState({ SeeAll: false });
  };

  SeeAllModal = () => {
    const { SeeAllMembersData, SeeAll } = this.state;
    return (
      <SeeAllStyledModal
        show={SeeAll}
        onHide={this.hideSeeAllModal}
        dialogClassName="modal-90w"
      >
        <Modal.Header>
          <CustomTitle>
            <div>
              <span>Team Details</span>
              <span className="closeImage" onClick={this.hideSeeAllModal}>
                <img src="/public/images/new_close_icon.png" />
              </span>
            </div>
          </CustomTitle>
        </Modal.Header>
        <Modal.Body>
          <TeamCardNew>
            <div className="teamDetails">
              {SeeAllMembersData.length > 0
                ? SeeAllMembersData.map((member, index) => (
                  <MemberDataV3
                    key={index}
                    captain={member.is_captain === 1}
                    border={index === 0}
                  >
                    <div className="imageDiv">
                      {" "}
                      {member.is_captain === 1 && (
                        <CaptainLogo src="/public/images/captain-band.png" />
                      )}
                      <img
                        src={`${ImageUrl}/${member.profile_image}`}
                        alt="people_icon"
                      />{" "}
                    </div>
                    <div className="textBox">
                      <h1>
                        {member.fullname}{" "}
                        {member.is_captain === 1 && (
                          <span className="max"> &nbsp;(Captain)</span>
                        )}{" "}
                      </h1>
                      {member.is_champion && (
                        <div className="wellnessChampion">
                          <img src={ "/public/images/AdminChallenges/Subtract.png"}/>
                          <span>Wellness Champion</span>
                        </div>
                      )}
                    </div>
                  </MemberDataV3>
                ))
                : null}
            </div>
          </TeamCardNew>
        </Modal.Body>
      </SeeAllStyledModal>
    );
  };

  setPagination = (activeIndex) => {
    if (activeIndex !== this.state.pageNo) {
      if (this.state.total <= 6) {
        this.setState(
          {
            minLimit: 1,
            maxLimit: this.state.total,
            pageNo: activeIndex,
          },
          () => {
            this.fetchCompanyTeamUsersAPI();
          }
        );
      } else {
        if (activeIndex === 1) {
          this.setState(
            {
              pageNo: activeIndex,
              maxLimit: 6,
              minLimit: 1,
            },
            () => {
              this.fetchCompanyTeamUsersAPI();
            }
          );
        } else {
          if (activeIndex + 3 > this.state.total) {
            this.setState(
              {
                pageNo: activeIndex,
                maxLimit: this.state.total,
                minLimit: this.state.total - 4,
              },
              () => {
                this.fetchCompanyTeamUsersAPI();
              }
            );
          } else {
            this.setState(
              {
                pageNo: activeIndex,
                maxLimit: activeIndex + 3,
                minLimit: activeIndex - 1,
              },
              () => {
                this.fetchCompanyTeamUsersAPI();
              }
            );
          }
        }
      }
    }
  };

  viewTeamDetails = (teamId, tName, index, count) => {
    this.setState({
      teamDetailsModal: true,
      teamName: tName ? tName : `Team ${index + 1}`,
      maxTeamMemeberCount: count,
    });
    this.props.fetchTeamDetailsById(teamId);
  };

  viewUsersToAdd = (id, count, teamMemberCount) => {
    const { fetchUsersToAddToTeam } = this.props;
    const { companyId } = this.state;
    this.setState({
      showModal: true,
      teamId: id,
      maxTeamMemeberCount: count,
      teamMembersCount: teamMemberCount,
    });
    let obj = {};
    obj["company_id"] = companyId;
    this.props.fetchTeamDetailsById(id);
    fetchUsersToAddToTeam(obj);
  };

  deleteTeam = (id) => {
    const { fetchDeleteTeam } = this.props;
    const { pageNo, companyId, limit } = this.state;
    this.setState(
      {
        deleteTeamID:null, showDeleteTeamPopup: false
      },
      () => {
        fetchDeleteTeam(id, companyId, pageNo, limit);
      }
    );
  };

  addNewTeam = () => {
    this.setState(
      {
        timeout: true,
      },
      () => {
        this.addNewTeamAPI();
      }
    );
  };

  addNewTeamAPI = () => {
    const { addChallengeTeamsV2 } = this.props;
    const { companyId, pageNo, limit } = this.state;
    const TeamDetails = {
      user_count: 25,
      team_count: 1,
      team_creation_type: "manual",
      company_id: companyId,
    };
    addChallengeTeamsV2(TeamDetails, companyId, pageNo, limit);
  };

  teamButtonStatusHover = () => {
    this.setState({
      teamButtonActive: true,
    });
  };

  teamButtonStatus = () => {
    this.setState({
      teamButtonActive: false,
    });
  };

  showTeamButton = () => {
    const { teamButtonActive, showTeams } = this.state;
    const{companyInfo, userCompany, searchAmigo, role}=this.props;

    const popover = () => (
      <Popover
        id="popover-basic"
        style={{
          width: "auto",
          padding: "0px 0px",
          background: "#FFFFFF",
          border: "1px solid #0D4270",
          boxShadow: "0px 13px 28px rgba(0, 0, 0, 0.3)",
          borderRadius: "6px",
        }}
      >
        <div
          style={{
            fontFamily: "Rubik",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "20px",
            color: "#0D4270",
            textAlign: "center",
            padding: "10px",
          }}
        >
          {"Add Individual Team"}
        </div>
      </Popover>
    );
    return (
      <BuddyTabContainer>
        <div style={{width: '50%', display: 'flex', justifyContent: 'flex-start'}}>
          <BuddyTab1
            active={showTeams ? false : true}
            onClick={() => this.hideShowTeam(false)}
            style={{width:"auto",padding:"0 15px"}}
          >
            {"Active"}
          </BuddyTab1>
          {(this.props.role==="ADMIN" || role === "WELLNESS_ADMIN" || role === "WELLNESS_CHAMPION") && <BuddyTab1 active={showTeams ? false : true} onClick={() => this.hideShowInactiveUsers(true)} style={{width:"auto",padding:"0 15px"}}> {"Inactive"}</BuddyTab1>}
          {!searchAmigo && ((role==="ADMIN"|| role === "WELLNESS_ADMIN" || role === "WELLNESS_CHAMPION") || userCompany && userCompany.showTeam) && <BuddyTab1 active={showTeams ? true : false}> {"Teams"}</BuddyTab1>}
        </div>
      
        {/* <HoverButton title={"Print"} onClick={()=>this.setState({isPrint:true})} svgPath={AddTeam()}/>  */}
        <div style={{display:"flex",flexDirection:"row", justifyContent:"end", width:"55%", marginRight:"15px"}}>
          <div style={{marginRight:"15px"}}><HoverButton title={"Add a Team"} svgPath={AddEmployeeIcon()} onClick={() => this.addNewTeam()} disabled={teamButtonActive} inActiveColor={"white"} activeColor={"#007AB1"} fillOpacity={"1"}/></div>
          <div> <HoverButton marginLeft={'0px'} title={"Download"} onClick={()=>this.setState({isPrint:true})} fillOpacity={1}  disabled={false} backgroundColor={"#f0f6f8"} backgroundColorActive={"#005c87"} activeColor={"#007AB1"} inActiveColor={"white"} svgPath={<PrintNewIcon/>}/>
          </div>
          {/* <ToolTip
            active={teamButtonActive}
            onMouseEnter={() => this.teamButtonStatusHover()}
            onMouseLeave={() => this.teamButtonStatus()}
            margin="0px"
          >
            <OverlayTrigger placement="bottom" overlay={popover()}>
              <div className='="toolTip' onClick={() => this.addNewTeam()}>
                {!teamButtonActive ? (
                  <img
                    src="/public/images/PeoplePage/addTeamDisable.svg"
                    alt="add team"
                  />
                ) : (
                  <img
                    src="/public/images/PeoplePage/addTeamActive.svg"
                    alt="add team"
                  />
                )}
              </div>
            </OverlayTrigger>
          </ToolTip> */}
        </div>
      </BuddyTabContainer>
    );
  };

  render() {
    const {
      paginationCount,
      pageNo,
      companyId,
      teamId,
      teamName,
      limit,
      teamDetailsModal,
      maxTeamMemeberCount,
      teamMembersCount,
      timeout,
      showDeleteTeamPopup,
      deleteTeamID,
      onHover
    } = this.state;
    const {
      teamsUsersData,
      teamsCount,
      teamUsers,
      teamDetailsData,
      teamIcons
    } = this.props;
    if (!teamsCount || isNull(teamsCount)) {
      return <Waiting />;
    }
    return (
      <Layout>
        <Main>
         
          {timeout && (
            <TeamCreationNotification>
              <div className="card-style">
                <div className="icon">
                  <img src="/public/images/whitetick.svg" alt="arrow" />
                </div>
                <div className="buttonName">
                  New Team card Added at last position of the queue
                </div>
              </div>
            </TeamCreationNotification>
          )}
          {this.showTeamButton()}
          {/* <HeaderWrapperV2 display="1" height="1">
            <div className="first">
              <LazyImage div={'teamIcon'} src={ImageUrl + "/images/PeoplePage/People_Group_Img.png"} alt="people_icon" />
              <div className="teamName">{teamsCount["team_count"]} Teams</div>
            </div>
            <div className="last"></div>
          </HeaderWrapperV2> */}


          <BottomCardWrapper >
            <BottomCard teamsWidth={true}>
              <div className="first">
                <div className="profile" >
                  {EmployeeCountIcon()}
                </div>
                <div className="count">{teamsCount["team_count"]} Teams</div>
              </div>
              <DividerLine teamsWidth={true}/>
            </BottomCard>
          </BottomCardWrapper>
          
         
          {teamsUsersData && teamsUsersData.length ? (
            <CardWrapper margin="1">
              <div className="teamWrapper">
                {teamsUsersData.map((team, index) => (
                  <TeamCard
                    key={index}
                    logo={team.logo !== null}
                    margin={(index + 1) % 3 === 0 ? true : false}
                  >
                    <div className="header">
                      <LazyImage div={"teamLogo"}
                        style={{width:team?.logo?.includes("teams-logo") && "32px",display:"flex",justifyContent:"center",alignItems:"center"}} 
                        src={
                          team.logo
                            ? `${ImageUrl}/${team.logo}`
                            :`${ImageUrl}/images/PeoplePage/teams-logo.svg`
                        }
                        alt="logo"
                      />
                      <div className="teamName">
                        {team.name ? team.name : `Team ${index + 1}`}
                      </div>
                      <div className="menuIcon">
                        <div>{MenuDotsIcon()}</div> 
                        <CustomDropdownMenue left="-105px" id="dropdown-recurring" className="kebab-list">
                          <CustomDropdownMenuItem  
                            eventKey={1}
                            onClick={() =>
                              this.viewTeamDetails(
                                team.id,
                                team.name,
                                index,
                                team.count
                              )
                            }
                          ><div className="menuText">  Edit Details</div>
                            <BottomStroke></BottomStroke>
                          </CustomDropdownMenuItem>
                          <CustomDropdownMenuItem
                            eventKey={2}
                            onClick={() => 
                              this.viewUsersToAdd(
                                team.id,
                                team.count,
                                team.team_users ? team.team_users.length : 0
                              )
                            }
                          >  
                            <div className="menuText">  Add Member</div>
                            <BottomStroke></BottomStroke>
                           
                          </CustomDropdownMenuItem>{" "}
                          <CustomDropdownMenuItem
                            eventKey={3}
                            onClick={() => this.showDeletePopup(team.id)}
                          > <div className="menuText">  Delete team</div>
                          </CustomDropdownMenuItem>
                        </CustomDropdownMenue>
                      </div>
                    </div>
                    {team.team_users && team.team_users.length ? (
                      <div className='wrapperData'onMouseEnter={() =>  team.team_users.length >= 5 && this.onHoverMouse(team.id)} onMouseLeave={() =>  team.team_users.length >= 5 && this.onLeaveHoverMouse()}>
                        {team && onHover === team.id &&
                      <div className='imageBackground'  >
                       
                        <div className="view-button"  onClick={() => this.handleSeeAll(team.team_users)}><div style={{marginRight:'10px'}}> <ViewProfileIcon/></div>{t("View Team")}</div>
                      </div>
                        }
                        
                        <div className="teamDetails">
                          {team.team_users.map(
                            (user, index) =>
                              index < 5 && (
                                <MemberData
                                  key={index}
                                  captain={user.is_captain == 1}
                                  last={index == 0}
                                >
                                  <LazyImage div={"memberProfile"}
                                    onError={this.fallBack}
                                    src={`${ImageUrl}/${user.profile_image}`}
                                    alt="people icon"
                                  />
                                  <div className="memberName">
                                    <div>
                                      <div style={{fontWeight:'bold',fontSize:'16px'}}>{user.fullname}</div>
                                      <div style={{float:'right'}}>
                                        {user.is_captain == 1 && (
                                          <div className="max">
                                           Captain
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    {user.is_champion && (
                                      <div className="wellnessChampionNew">
                                       
                                        <span>  <div>{wellnessChampionIcon()}</div>Wellness Champion</span>
                                      </div>
                                    )}
                                  </div>
                                  {/* {user.is_captain == 1 && (
                                    <LazyImage
                                      className="captain"
                                    src={ImageUrl + "/images/captain-band.png"}
                                  />
                                  )} */}
                                </MemberData>
                              )
                          )}
                          {/* {team.team_users.length > 5 && (
                            <div
                              className="viewAllMembers"
                              onClick={() => this.handleSeeAll(team.team_users)}
                            >
                              See All
                            </div>
                          )} */}
                        </div>
         
         
                      </div>

                    ) : (
                      <div className="teamDetailsV2">
                        <AddMemberButton
                          onClick={() =>
                            this.viewUsersToAdd(
                              team.id,
                              team.count,
                              team.team_users ? team.team_users.length : 0
                            )
                          }
                        >
                          + Add Member
                        </AddMemberButton>
                      </div>
                    )}
                    
                  </TeamCard>
                  
                ))}
                <AddNewTeam>
                  <TeamImage>
                    <img src="/public/images/AdminTeam/createTeamsIcon.png" />
                  </TeamImage>
                  <TeamButton width={'340px'} style={{background: '#007AB1', marginTop:'20px'}}  onClick={() => this.addNewTeam()}>
                    <div
                      className="buttonName"
                    >
                      <div style={{marginRight: '10px'}}>
                        <AddTeamIcon />
                      </div>
                     Add a team
                    </div>
                  </TeamButton>
                </AddNewTeam>

              </div>

           

              {paginationCount > 15 && (
                <PaginationCard>
                  {pageNo > 1 && (
                    <UserButton
                      width="30px"
                      height="34px"
                      Align={1}
                      float={1}
                      fontSize="14px"
                      bgColor="rgba(0, 122, 177, 0.05)"
                      capitalize={1}
                      onClick={() => this.setPagination(pageNo - 1)}
                    >
                      <div style={{transform:"rotate(180deg)",marginLeft:"20px"}}> {PaginationIcon()}</div>
                    </UserButton>
                  )}
                  {this.getPaginationCircle()}
                  {pageNo != this.state.total && (
                    <UserButton
                      width="30px"
                      height="34px"
                      Align={1}
                      marginLeft="10px"
                      fontSize="14px"
                      bgColor="rgba(0, 122, 177, 0.05)"
                      capitalize={1}
                      onClick={() => this.setPagination(pageNo + 1)}
                    >
                      <div> {PaginationIcon()} </div>
                    </UserButton>
                  )}
                </PaginationCard>
              )}
            </CardWrapper>
          ) : (
            <CardWrapper>
              <div className="image">
                <img src="/public/images/AdminTeam/createTeamsIcon.png" />
              </div>
              <div className='teamText'>
               
                Create teams to help connect people across your organization,
                build workplace wellbeing, and strengthen company culture. 
              </div>
              <div className="container">



                <TeamButton  style={{background: '#007AB1'}} onClick={() => this.teamRedirect()}>
                  <div
                    className="buttonName"
                    onClick={() => this.teamRedirect()}
                  >
                    <div style={{marginRight: '10px'}}>
                      <AddTeamIcon />
                    </div>
                   Create Team
                  </div>
                </TeamButton>
              </div>
            </CardWrapper>
          )}
        </Main>
        {this.SeeAllModal()}
        <AddUsersToTeamPopup
          showModal={this.state.showModal}
          hideModal={this.hideAddEmployeePopUp}
          teamUsers={teamUsers}
          companyId={companyId}
          teamId={teamId}
          teamcount={teamDetailsData}
          pageNo={pageNo}
          limit={limit}
          memberCount={maxTeamMemeberCount}
          teamMembersCount={teamMembersCount}
        />
        {teamDetailsModal && (
          <TeamDetailsPopup
            peopleTeams={true}
            showAddModal={this.viewUsersToAdd}
            showModal={this.state.teamDetailsModal}
            hideModal={this.hideTeamDetailsPopUp}
            teamDetailsData={teamDetailsData}
            teamIcons={teamIcons[0]}
            defaultTeamName={teamName}
            pageNo={pageNo}
            limit={limit}
            companyId={companyId}
            maxTeamMemeberCount={maxTeamMemeberCount}
          />
        )}
        {showDeleteTeamPopup&& 
          <DeletePopup
            deleteId={deleteTeamID}
            showConfirmPopup={showDeleteTeamPopup}
            showHideDeletePopup={this.hideDeletePopup}
            deleteData={this.deleteTeam}
            popupTitle="Delete Team"
            popupHeading="Are you sure you want to delete this Team?"
          />}

        {this.state.isPrint && <CSVList Title={"Team Users"} removeCSVData={()=>this.setState({isPrint:false})} headers={this.state.csvHeaders} data={this.state.teamUsers || []} />}
      </Layout>
    );
  }
}

AdminTeamMain.defaultProps = {
  companyInfo: { id: 1, name: "" },
};

AdminTeamMain.propTypes = {
  history: PropTypes.object.isRequired,
  teamsUsersData: PropTypes.array,
  fetchCompanyTeamUsers: PropTypes.func,
  companyInfo: PropTypes.object,
  teamsCount: PropTypes.array,
  fetchCompanyTeamCount: PropTypes.func,
  fetchUsersToAddToTeam: PropTypes.func,
  teamUsers: PropTypes.array,
  fetchTeamDetailsById: PropTypes.func,
  teamDetailsData: PropTypes.array,
  fetchDeleteTeam: PropTypes.func,
  teamIcons: PropTypes.array,
  addChallengeTeams: PropTypes.func,
  addChallengeTeamsV2: PropTypes.func,
  role:PropTypes.string,
  userCompany: PropTypes.object,
  searchAmigo: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  teamsUsersData: state.adminTeamCreation.teamsUsersData,
  teamsCount: state.adminTeamCreation.teamsCount,
  teamUsers: state.adminTeamCreation.teamUsers,
  teamDetailsData: state.adminTeamCreation.teamDetailsData,
  teamIcons: state.adminTeamCreation.teamIconsData,
  userCompany: state.profileData.userCompany
});

const mapDispatchToProps = (dispatch) => ({
  fetchCompanyTeamUsers: (companyId, PageNo, limit) => dispatch(fetchCompanyTeamUsers(companyId, PageNo, limit)),
  fetchCompanyTeamCount: (companyId) => dispatch(fetchCompanyTeamCount(companyId)),
  fetchUsersToAddToTeam: (object) => dispatch(fetchUsersToAddToTeam(object)),
  fetchTeamDetailsById: (teamId) => dispatch(fetchTeamDetailsById(teamId)),
  fetchDeleteTeam: (teamId, companyId, pageNo, limit) => dispatch(fetchDeleteTeam(teamId, companyId, pageNo, limit)),
  addChallengeTeams: (obj, companyId) => dispatch(addChallengeTeams(obj, companyId)),
  addChallengeTeamsV2: (obj, companyId, pageNo, limit) => dispatch(addChallengeTeamsV2(obj, companyId, pageNo, limit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AdminTeamMain));